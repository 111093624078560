.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.profilPic {
  display: flex;
  max-width: 100%;
  height: auto;
}

.form {
  width: 100%;
  clip-path: polygon(0 0, 100% 0%, 100% 63%, 0 54%);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 78%, 0 69%);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.name {
  margin-top: -10%;
}

.global-resume {
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: -9rem;
  margin: 0 4rem 0 4rem;
}

.resume {
  text-align: center;
  flex-basis: 60%;
}

.dev-vector {
  width: 100%;
  max-width: 300px;
  height: auto;
  flex-basis: 40%;
}

.list {
  display: flex;
  text-align: center;
  justify-content: center;
}

.list-cate {
  margin: 4rem 1rem;
  &:hover {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
    cursor: pointer;
  }
}

.icon-list {
  max-height: 4rem;
}

@media only screen and (max-width: 900px) {
  .form {
    height: auto;
    width: 100%;
    -webkit-clip-path: none;
    clip-path: none;
    justify-content: center;
    padding: 2rem;
  }
  .profilPic {
    display: none;
  }
  .intro {
    display: block;
    height: auto;
  }
  .name {
    text-align: center;
    margin: 0;
  }
  .global-resume {
    display: block;
    margin: 1rem;
  }
  .dev-vector-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dev-vector {
    display: block;
  }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .form {
    align-items: flex-end;
  }
}
