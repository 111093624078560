.cardGlobal {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
}

.cardCustom {
  border-radius: 1rem;
  max-width: 24rem;
  margin-bottom: 1rem;
  &:hover {
    -webkit-transform: scale(1.09);
    transform: scale(1.09);
    cursor: pointer;
  }
}

.titleCard h5 {
  color: black;
}

.bodyCard p {
  color: black;
  text-shadow: none;
}

.collaspeCardTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: black !important;
}

@media screen and (max-width: 900px) {
  .cardCustom {
    max-width: 21rem;
  }

  ul {
    list-style: none;
  }
}
