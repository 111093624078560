.loadingOff {
  display: none !important;
}

.form-css {
  display: block;
  padding-top: 5%;
  margin-right: 10%;
  margin-left: 10%;
  position: relative;
}

.form-position {
  position: relative;
  z-index: 1;
}

.loading-center {
  text-align: center;
  padding-bottom: 3%;
}

.captcha {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 500px) {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

$button-bg-color: #073150;
$button-border-color: #166e84;

.center {
  text-align: center;
  margin-top: 1.5%;
}

.button {
  margin-right: 5px;
  height: 50px;
  min-width: 100px;
  background: $button-bg-color;
  border: none;
  outline: none;
  color: white;
  font-family: inherit;
  font-weight: 400;
  font-size: 20px;
  border-radius: 3px;
  box-shadow: 0 5px 0px darken($button-bg-color, 0%);
  border-bottom: 2px solid darken($button-bg-color, 3%);

  &:hover {
    background: darken($button-bg-color, 5%);
    box-shadow: 0 4px 1px darken($button-bg-color, 5%);
    border-bottom: 2px solid darken($button-bg-color, 8%);
    transition: all 0.1s ease-in;
  }

  &:active {
    transform: translateY(4px);
    border-bottom-width: 2px;
    box-shadow: none;
  }
}

.arrow-background-form {
  -webkit-clip-path: polygon(50% 8%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
  clip-path: polygon(50% 8%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
  margin-right: 3%;
  margin-left: 3%;
  border-radius: 12px;
}

.ligne-form {
  border-radius: 60rem;
  width: 59%;
  height: 69%;
  transform: translateY(0px) translateX(0px) rotate(84deg);
  position: absolute;
  top: -3rem;
  right: 46%;
}

@media screen and (max-width: 900px) {
  .arrow-background-form {
    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
    clip-path: polygon(50% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
  }
}
