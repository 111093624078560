.buttons{
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index:1;
    margin:1rem;
}

.icon{
margin-right:1rem;
height:3rem;
width:auto;
&:hover{
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
}


.language{
margin-left:1rem;
height:3rem;
width:auto;
}