.vitrine {
  padding-top: 2rem;
}

.title {
  text-align: center;
  padding-top: 3%;
  color: white;
}

.background-intro {
  -webkit-clip-path: polygon(50% 0, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
  clip-path: polygon(50% 0, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
  margin-right: 3%;
  margin-left: 3%;
  border-radius: 12px;
  margin-bottom: 6%;
  position: relative;
}

.img-intro-text {
  height: 80px;
  width: 80px;
  float: left;
  margin-right: 4%;
}

.intro-text {
  font-size: 1.3em;
  margin-top: 3%;
  padding: 3rem;
  transition: 1s;
  z-index: 1;
  position: relative;
  :hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    .img-intro-text {
      height: 80px;
      width: 80px;
      float: left;
      margin-right: 4%;
    }
  }
}

.img-intro-text-mobile {
  height: 80px;
  width: 80px;
  float: right;
  margin-left: 4%;
}

.intro-text-partdeux {
  text-align: right;
  font-size: 1.3em;
  width: 100%;
  margin-bottom: 3%;
  padding: 0 3rem 3rem 3rem;
  z-index: 1;
  position: relative;
  :hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    .img-intro-text-mobile {
      height: 80px;
      width: 80px;
      float: right;
      margin-left: 4%;
    }
  }
}

.ligne {
  border-radius: 60rem;
  width: 45%;
  height: 114%;
  transform: translateY(0px) translateX(0px) rotate(-26deg);
  position: absolute;
  bottom: -12rem;
  left: -15%;
}

.background-intro-deux {
  -webkit-clip-path: polygon(50% 8%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
  clip-path: polygon(50% 8%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
  margin-right: 3%;
  margin-left: 3%;
  border-radius: 12px;
  margin-bottom: 6%;
  position: relative;
}

.intro-text-deux {
  font-size: 1.3em;
  margin-top: 3%;
  padding-top: 4%;
  padding-bottom: 2%;
  margin-right: 9%;
  margin-left: 9%;
  z-index: 1;
  position: relative;
}

.background-intro-deux .intro-text-deux {
  padding-right: 1%;
}

.intro-text-deux:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.img-service-column {
  display: flex;
  flex-direction: column;
  float: left;
  margin-right: 3%;
}

.img-service {
  height: 70px;
  width: 70px;
  margin: 1rem;
}

.ligne-deux {
  border-radius: 60rem;
  width: 45%;
  height: 142%;
  transform: translateY(0px) translateX(0px) rotate(205deg);
  position: absolute;
  top: -11rem;
  right: 14%;
}

@media screen and (max-width: 900px) {
  .background-intro-deux {
    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
    clip-path: polygon(50% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
  }
  .intro-text-deux {
    padding-bottom: 3rem;
  }

  .ligne,
  .ligne-deux {
    display: none;
  }
}
