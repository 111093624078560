@font-face {
  src: url(fonts/AlfaSlabOne-Regular.ttf);
  font-family: alfa;
  font-display: swap;
}

* {
  box-sizing: border-box;
  //border: red solid 1px;
}

body {
  overflow-x: hidden;
}

p,
span,
label,
h1,
h2,
h3,
h4,
h5 {
  color: white;
}

h1,
h2 {
  font-family: alfa, sans-serif, cursive;
}

a {
  text-decoration: none;
}

.dark {
  background-color: #0f131b;
  p,
  span,
  label,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
    text-shadow: 0px 4px 7px rgba(0, 0, 0, 1);
  }
  color: white;
  text-shadow: 0px 4px 7px rgba(0, 0, 0, 1);
}

.light {
  background-color: #eee4e1;
  p,
  span,
  label,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: rgb(0, 0, 0) !important;
    text-shadow: 0px 1px 5px rgb(255 255 255);
  }
  color: rgb(0, 0, 0) !important;
  text-shadow: 0px 1px 5px rgb(255 255 255);
}

.background-form-dark {
  background-color: #052136;
}

.background-form-light {
  background-color: #fef5ee;
}

.ligne-dark {
  background: linear-gradient(to top, #007999, rgba(255, 255, 255, 0));
}

.ligne-light {
  background: linear-gradient(to top, #20a39e, rgba(255, 255, 255, 0));
}
